import axios, { InternalAxiosRequestConfig } from 'axios'

import { getAuth } from 'firebase/auth'
import { constants } from 'config/constants'
import { fireSwalError } from 'utils/error-handler'

axios.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const { currentUser } = getAuth()
    const adminWebsiteId = localStorage.getItem(constants.LOCAL_STORAGE.ADMIN_AMEGO_WEBSITE_ID)
    const websiteId =
      currentUser && localStorage.getItem(constants.LOCAL_STORAGE.AMEGO_WEBSITE_ID(currentUser.uid))
    return {
      ...config,
      headers: {
        ...config.headers,
        ...(websiteId || adminWebsiteId
          ? { 'x-amego-website-id': adminWebsiteId ?? websiteId }
          : {}),
        ...(currentUser ? { Authorization: `Bearer ${await currentUser.getIdToken()}` } : {}),
      },
    } as InternalAxiosRequestConfig
  },
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    fireSwalError(error)
    return Promise.reject(error)
  },
)
